.containerAddress {
  margin-left: 40px;
  /* margin-top: 50px; */
  width: 300px;
  padding: 0px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 0px;
}

.subTitle {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.address1 {
  margin-top: 15px;
}
.topText {
  position: absolute;
  margin-top: -7px;
  margin-left: 15px;
  font-size: 12px;
  text-align: left;
  padding-left: 3px;
  padding-right: 3px;
  background-color: white;
}

.wideInput {
  text-align: left;
  height: 45px;
  width: 290px;
}

.bottomBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.backBtnAddress {
  width: 72px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.continueBtn {
  width: 202px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.continueDisableBtn {
  width: 202px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: #6f6f6f;
  background: #e1e1e1;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
