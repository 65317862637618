.welcome_page {
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.welcome_page_img {
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: end;
  margin-bottom: 65px;
}
.welcome_page_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
}
.welcome_page_text {
  font-style: normal;
  font-weight: 500;
  font-size: 35.05px;
  line-height: 54px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
}
.welcome_page_text1 {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 19.51px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
}
.welcome_page_btn {
  background: #0bcb49;
  border-radius: 34.375px;
  width: 275px;
  height: 58.17px;
  /* left: 12071px;
  top: 8284px; */
  /* justify-content: center; */
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 24px;
  color: #ffffff;
}
.btn_align {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}

.bottom_box {
  margin: 80px auto;
  display: flex;
  width: 600px;
  padding: 32px 20px 32px 32px;
  border-radius: 16px;
  border-bottom: 1px solid var(--primary-300, #d6bbfb);
  background: var(--primary-25, #fcfaff);
}

.bottom_bold_text {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
}

.bottom_text {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media screen and (max-width: 768px) {
  .welcome_page_img {
    margin-left: 10px;
  }
  .welcome_page_heading {
    /* display: flex; */
    position: relative;
    font-weight: 800;
    font-size: 60.5291px;
    /* margin-left: 50px; */
  }
}
