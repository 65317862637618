.headerContain {
  margin-left: 40px;
  top: 0px;
  margin-bottom: 20px;
  padding-top: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  /* position: fixed; */
  background-color: white;
}

.headerName {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin: 0px;
  position: fixed;
}
