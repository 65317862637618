.container {
  margin-left: 40px;
  /* margin-top: 50px; */
  width: 300px;
  padding: 0px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 0px;
}

.subTitle {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.bottomBtn1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}
.backBtn {
  width: 72px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.continueBtn {
  width: 202px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.cardDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.box {
  border: 1px solid black;
  border-radius: 4px;
  height: 30px;
  padding: 10px;
}

.box1 {
  border: 1px solid black;
  border-radius: 4px;
  width: 120px;
  height: 30px;
  padding: 10px;
}

.box2 {
  border: 1px solid black;
  border-radius: 4px;
  width: 110px;
  height: 30px;
  padding: 10px;
}

.secureText {
  font-size: 10px;
  font-weight: 400;
}

.referalText {
  font-size: 11px;
  font-weight: 400;
  color: #004eeb;
}
