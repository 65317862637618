.container {
  margin-left: 40px;
  /* margin-top: 50px; */
  width: 300px;
  padding: 0px;
}

.containerPending {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.scrollContainer {
  overflow-y: auto;
  height: auto;
  max-height: 75vh;
}

.topLine {
  border-bottom: 1px solid gray;
}

.priceLine {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0px;
  margin-top: 15px;
}

.line1 {
  display: flex;
  flex-direction: row;
}

.line2 {
  display: flex;
  flex-direction: row;
}
.kichenName {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 0px;
  margin-bottom: 15px;
}

.kitchenRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderId {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin: 0px;
  margin-bottom: 15px;
  margin-left: auto;
}

.price {
  display: flex;
  flex-direction: column;
}
.dishName {
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.dishPrice {
  margin-left: auto;
  width: 100px;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}

.dishQuantity {
  position: absolute;
  margin-left: 100px;
  text-align: right;
  width: 100px;
  font-size: 12px;
  font-weight: 400;
}

.dishDescription {
  width: 220px;
  margin: 0;
  font-size: 12px;
}

.itemContainer {
  margin-bottom: 0px;
}

.cancelBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: black;
  background: white;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
  margin-top: 100px;
}

.claimBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.main_header {
  width: 360px;
  height: 165px;
  background-color: #c8fff3;
}

.header {
  margin-left: 40px;
  width: 300px;
  height: 165px;
  padding: 0.1px;
}

.order_date {
  height: 94px;
  display: flex;
}
.order_detail {
  width: 70%;
}
.order_hotel {
  width: 212px;
  height: 41px;
  display: inline-block;
}
.order_hotel_name {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  /* margin-left: 35px; */
  display: inline;
}
.bill_order_date {
  width: 166px;
  height: 34px;
  display: inline-block;
}
.order_date_id {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  /* margin-left: 35px; */
  display: inline;
}

.logo_right_vector {
  height: 120px;
  width: 30%;
}
.logo_right {
  display: flex;
  height: 84px;
  width: 60px;
}

.headerContainRec {
  /* margin-left: 40px; */
  top: 0px;
  margin-bottom: 20px;
  padding-top: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  /* position: fixed; */
  background-color: #c8fff3;
}

.headerContainFail {
  /* margin-left: 40px; */
  top: 0px;
  margin-bottom: 20px;
  padding-top: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  /* position: fixed; */
  background-color: white;
}
