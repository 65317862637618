.container {
  margin-left: 40px;
  /* margin-top: 50px; */
  width: 300px;
  padding: 0px;
}

.shareTitle {
  font-size: 10px;
  font-weight: 400;
  color: black;
  margin: 0px;
}

.date {
  font-size: 10px;
  font-weight: 400;
  color: black;
  margin: 0px;
  margin-left: 20px;
}

.receipt {
  font-size: 10px;
  font-weight: 400;
  color: #004eeb;
  margin: 0px;
  margin-left: auto;
}

.titleRow {
  display: flex;
  flex-direction: row;
}

.titleCode {
  font-size: 10px;
  font-weight: 400;
  color: black;
  margin: 0px;
  text-align: center;
  padding-top: 5px;
}

.code {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin: 0px;
  text-align: center;
  margin-top: 2px;
}
.shareCodeBox {
  width: 105px;
  height: 50px;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.secondRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.cookNow {
  font-size: 10px;
  font-weight: 400;
  color: black;
  margin-top: auto;
  margin-right: 10px;
}

.cookdiv {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.topLines {
  border-bottom: 2px solid #f4f4f4;
  margin-top: 10px;
}

.claimBtn {
  width: 300px;
  height: 50px;
  /* margin: 10px auto; */
  justify-content: center;
  display: block;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 400;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
