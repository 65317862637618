.kitchencontainer {
  width: 320px;
}

.kitchentapbutton {
  color: var(--primary-700, #6941c6);
  text-align: center;

  /* Text xs/Medium */
  font-size: 12px;
  /* font-family: Inter;
  font-style: normal; */
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  border: 1px solid var(--primary-200, #e9d7fe);
  background: var(--primary-50, #f9f5ff);
  padding: 2px 8px;
  margin: 5px;
  cursor: pointer;
}

.kitchentapbutton.selected {
  background-color: black;
  color: white;
}

.kitchenmenuLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.kitchenItemRow {
  margin: 0;
  margin-left: 35px;
}

.kitchenItemRowLine1 {
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.kitchenDishName {
  margin: 0;
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 12px;
  /* font-family: Roboto;
  font-style: normal; */
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.kitchenDishPrice {
  margin: 0;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  font-size: 12px;
  /* font-family: Roboto;
  font-style: normal; */
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.kitchenDescription {
  margin: 0;
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 12px;
  /* font-family: Roboto;
  font-style: normal; */
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.15px;
  margin-left: 10px;
}

.kitchenDishImage {
  margin: 0;
  width: 46.586px;
  height: 47.835px;
  flex-shrink: 0;
  border-radius: 4px;
}

.kitchenHeader {
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.kichenNumber {
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 14px;
  /* font-family: Roboto;
  font-style: normal; */
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.15px;
  margin: 0;
}

.kichenNumberView {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kitchenCallText {
  color: var(--00-on-surface-high-emphasis, rgba(0, 0, 0, 0.87));
  font-size: 12px;
  /* font-family: Roboto;
  font-style: normal; */
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.15px;
  margin: 0;
}

.poweredBy {
  width: 290px;
  height: 52px;
  position: fixed;
  bottom: 30px;
  left: 35px;
}
