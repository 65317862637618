.containercancel {
  margin-left: 35px;
  /* margin-top: 50px; */
  width: 350px;
  padding: 0px;
  background: #ffe7df;
  height: 100vh;
}
.orderolder {
  color: var(--warning-700, #b54708);
  margin-top: 224px;
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  border: 1px solid var(--warning-200, #fedf89);
  background: var(--warning-100, #fef0c7);
  display: inline-flex;
  padding: 4px 12px;
  align-items: center;
  /* justify-content: center; */
  margin-left: 103px;
  gap: 8px;
}
.ordercancelkitchen {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  display: flex;
  width: 359px;
  height: 33px;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 22px;
}
.ordernow {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  width: 360px;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 13px;
}
.backBtn {
  display: flex;
  width: 290px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #000;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 30px;
  margin-top: 86px;
}
